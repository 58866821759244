<template>
  <b-row class="align-items-start justify-content-left">
    <b-col
      md="4"
      class="mb-1 mb-md-0"
    >
      <Avatar v-if="item.lesson.image"
              :image="item.lesson.image"
              :rounded="true"
              class="lesson-image"
      />
      <b-avatar v-else
                variant="light-warning"
                square
      >
        <span>{{ item.lesson.name?.charAt(0) }}</span>
      </b-avatar>

    </b-col>
    <b-col
      md="8"
      class="pl-2 detail-responsive"
    >
      <h4>{{ item.lesson.name }}</h4>
      <h6 class="text-muted">
        {{ $t('users-module.assigned') }} {{ FROM_NOW(item.start_time) }}
      </h6>
      <div class="mt-1">
        <b-badge
          class="mb-1 mr-1 class-detail"
          variant="primary"
          size="sm"
          style="white-space: normal; word-break: break-word;"
          @click="openClassRoom(item.class_id)"
          @click.stop
        >
          {{ item.class?.name || $t('labels.classroom') }}
        </b-badge>
        <b-badge
          variant="success"
          size="sm"
          class="calendar"
          @click="openCalendar(item.class_id)"
          @click.stop
        >
          {{ $t('student-lab-module.calendar') }}
        </b-badge>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BAvatar, BBadge,
} from 'bootstrap-vue'
import Avatar from '@/views/common/components/Avatar.vue'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BBadge,
    Avatar,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openCalendar(roomId) {
      this.$router.push({ name: 'student-class-calendar', params: { classId: roomId } })
    },
    openClassRoom(roomId) {
      this.$router.push({ name: 'student-view-students', params: { id: roomId } })
    },
  },
}
</script>
